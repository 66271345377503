import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/auth/login";
import VerifyOtp from "./components/auth/verifyOtp";
import Profile from "./components/profile/profile";
import LoansList from "./components/loanapplications/loansList";
import Layout from "./components/appLayout";
import interceptor from "./interceptor";
import ProductList from "./components/products/productsList";
import OrganisationList from "./components/organisations/organisationsList";
import UserList from "./components/users/usersList";
import LoanDetailedView from "./components/loanapplications/loanDetailedView";
import { BackendType } from "./redux/reducers/appStateReducer";
import { setEnvironment } from "./redux/actions/appStateAction";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SectionList from "./components/sections/sectionsList";
import Branches from "./components/branches/branchesList";

function App() {
  useEffect(() => {
    // Setting the developement server and Interceptor.
    store.dispatch(setEnvironment(BackendType.Production));
    interceptor.interceptor();
  }, []);
  const ProtectedRoute = ({ children }: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      // Optionally, you can add more checks like token validity here
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="mainApp">
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Navigate to="/organisations" />} />
                <Route path="/organisations" element={<OrganisationList />} />
                <Route path="/loans" element={<LoansList />} />
                <Route
                  path="/loan-detailed-view"
                  element={<LoanDetailedView />}
                />
                <Route path="/profile" element={<Profile />} />

                <Route path="/products" element={<ProductList />} />
                <Route path="/users" element={<UserList />} />
                <Route path="/branches" element={<Branches />} />
                <Route path="/sections" element={<SectionList />} />
              </Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
            <ToastContainer
              className="toast-position"
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
