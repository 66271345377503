import React from "react";
import { Outlet } from "react-router-dom";
import MenuSideBar from "../components/menusidebar/menuSideBar";
import "../components/appLayout.css";

const Layout = () => {
  return (
    <div className="layoutContainer">
      <MenuSideBar />
      <div className="contentContainer">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
