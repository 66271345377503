// reducers/index.ts
import { combineReducers } from "redux";
import authReducer from "./authReducer"; // Auth reducer, define it below
import appStateReducer from "./appStateReducer";
import loanReducer from "./loanReducer";

export const rootReducer: any = combineReducers({
  appStateReducer: appStateReducer,
  auth: authReducer,
  loan: loanReducer
});

// Define the RootState type to be used across your app
export type RootState = ReturnType<typeof rootReducer>;
