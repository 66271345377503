export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SET_THEME = "SET_THEME";
export const IS_ERROR = "IS_ERROR";
export const IS_ERROR_MSG = "IS_ERROR_MSG";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_TOKEN = "SET_TOKEN";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ENVIRONMENT = "SET_ENVIRONMENT";
export const SET_BASE_URL = "SET_BASE_URL";
export const SET_USER_BRANCHES = "SET_USER_BRANCHES";
export const SET_USER_PRODUCTS = "SET_USER_PRODUCTS";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_USER_ORGANISATIONS = "SET_USER_ORGANISATIONS";
export const SET_SELECTED_ORGID = "SET_SELECTED_ORGID";
export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";
