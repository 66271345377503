// store.ts
import { createStore, applyMiddleware, Store } from "redux";
import { thunk, ThunkMiddleware } from "redux-thunk";
import { rootReducer } from "../redux/reducers/reducers"; // Your reducers
import { AppActions } from "./models/actionTypes/appActionType";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

export type AppState = ReturnType<typeof rootReducer>;
const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);
export const persistor = persistStore(store);
