import React, { useState } from "react";
import "./coBorrowerDetails.css";

const CoBorrowerDetails = ({ data }: any) => {
  const coBorrowerData = data;

  return (
    <div
      className={
        coBorrowerData?.length ? "co-borrower-main" : "co-borrower-main-no-data"
      }
    >
      {coBorrowerData?.length ? (
        coBorrowerData?.map((each: any) => {
          return (
            <div key={each?.name} className="co-borrower-main-div">
              <img
                src={each.image}
                alt="co borrower photo"
                className="co-borrower-image"
              />
              <div className="co-borrower-photo-main-div">
                <div className="co-borrower-details">
                  <div className="co-borrower-label-text">Name: </div>
                  <div className="co-borrower-ac-business-text">
                    {each?.name}
                  </div>
                </div>
                <div className="co-borrower-details">
                  <div className="co-borrower-label-text">DOB: </div>
                  <div className="co-borrower-ac-business-text">
                    {each?.dob}
                  </div>
                </div>
                <div className="co-borrower-details">
                  <div className="co-borrower-label-text">Gender: </div>
                  <div className="co-borrower-ac-business-text">
                    {each?.gender}
                  </div>
                </div>
                <div className="co-borrower-details">
                  <div className="co-borrower-label-text">Relation: </div>
                  <div className="co-borrower-ac-business-text">
                    {" "}
                    {each?.relation}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="ac-no-data">No Co-Borrower Details</div>
      )}
    </div>
  );
};

export default CoBorrowerDetails;
