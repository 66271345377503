import Axios from "axios";
import { AppState, store } from "./redux/store";

const interceptor = () => {
  Axios.interceptors.request.use(
    (conf) => {
      const { auth } = store.getState() as AppState;
      const token = auth?.userDetails?.token || "";
      const orgId = auth?.userDetails?.orgId || "";
      if (token) {
        conf.headers["Authorization"] = `bearer ${token}`;
        conf.headers["x-tenant-id"] = orgId;
      }
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  Axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      // You can handle error here and trigger warning message without get in the code inside

      return Promise.reject(error);
    }
  );
};
export default {
  interceptor,
};
