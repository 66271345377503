import "../auth/login.css";
import lg from "../../assets/logos/lg1.jpg";
import logo from "../../assets/logos/logo.png";
import { useState } from "react";
import { isEmpty } from "../../utils/helpers";
import { logInApi } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import LeftView from "./leftView";
import { toast } from "react-toastify";
const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    mobile: "",
    country_code: "+91",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    mobile: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    setShowAlert(false);
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    try {
      let channel;
      if (!isEmpty(formData.mobile)) {
        channel = "mobile";
      }
      if (!channel) {
        console.log("Mobile Number is required");
        setErrors({
          ...errors,
          email: "Mobile Number is required",
        });
        setShowAlert(true);
      }
      const address = formData.mobile;
      const res = await logInApi({
        channel: channel,
        address,
        country_code: formData.country_code,
      });
      console.log(res.data);
      if (res.data) {
        toast.success("Otp Sent To Your Mobile Number");
        localStorage.setItem(
          "auth_details",
          JSON.stringify({
            channel: channel,
            address: address,
            country_code: formData.country_code,
          })
        ); // Save identifier to local storage
        navigate("/verify-otp");
      }
    } catch (e: any) {
      toast.error(JSON.parse(JSON.stringify(e?.response?.data?.msg)));
      console.log(e);
    }
  };

  return (
    <div className="mainContainer">
      <div className="leftContainer">
        <LeftView />
      </div>
      <div className="rightContainer">
        <div className="rightHeader">
          <div className="loginText">Login</div>
        </div>
        <form onSubmit={handleLogin} className="formClass">
          <div className="labelTextPhone">Phone Number</div>
          <input
            type="tel"
            placeholder="Enter Phone Number"
            onChange={handleChange}
            name="mobile"
            className="inputField"
          />
          <button
            className="sendOtpButton"
            type="submit"
            disabled={isEmpty(formData.mobile)}
          >
            Send OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
