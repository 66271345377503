import { useState } from "react";
import "./generateCamForm.css";
import { publishCamData, saveCamData } from "../../services/loanService";
import { toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoMdRemoveCircle, IoMdAddCircle } from "react-icons/io";

const GenerateCamForm = ({
  data,
  application_id,
  onPublishEdit,
  status,
  onSaveEdit,
}: any) => {
  const [formData, setFormData] = useState(data);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  // function for updating state of input fields directly
  // params: parentKeys : comma separated string of parentKeys till the input field,
  // value: value of input
  const updateNestedState = (parentKeys: any, value: any) => {
    let keysArray = parentKeys.split(",");
    let updatedFormData = { ...formData }; // Cloning the existing formData

    // Reference to the current level of the object
    let currentLevel: any = updatedFormData;

    // Loop through the keys and create nested objects
    keysArray.forEach((key: string, index: number) => {
      if (index === keysArray.length - 1) {
        // If it's the last key, assigning the value
        currentLevel[key] = value;
      } else {
        // Otherwise, move deeper into the object
        if (!currentLevel[key]) {
          currentLevel[key] = {}; // Creating only if it doesn't exist
        }
        currentLevel = currentLevel[key]; // Moving deeper into the object
      }
    });

    // Updating formData state with the updated object
    setFormData(updatedFormData);
  };

  // function for updating state of input fields in table
  // params: parentKeys : comma separated string of parentKeys till the input field,
  // value: value of input,
  // indexes: indexes of array(data array in table) element that needs to be changed
  const updateDataInTable = (
    parentKeys: string,
    indexes: [number, number],
    value: string
  ) => {
    let keysArray = parentKeys.split(",");
    let updatedFormData = { ...formData }; // Cloning the existing formData
    let currentLevel: any = updatedFormData;

    // Looping through the keys and create nested objects
    keysArray.forEach((key: string, index: number) => {
      if (index === keysArray.length - 1) {
        // If it's the last key, assigning the value
        const data = [...currentLevel[key].data];
        data[indexes[0]][indexes[1].toString()] = value;
        currentLevel[key]["data"] = data;
      } else {
        // Otherwise, moving deeper into the object
        if (!currentLevel[key]) {
          currentLevel[key] = {}; // Creating only if it doesn't exist
        }
        currentLevel = currentLevel[key]; // Moving deeper into the object
      }
    });

    // Updating formData state with the updated object
    setFormData(updatedFormData);
  };

  // function for removing a new row in data field of a table
  // params: parentKeys : comma separated string of parentKeys till the table display,
  // rowIndex: Index to be removed from the table

  const removeRowFromTable = (parentKeys: string, rowIndex: number) => {
    let keysArray = parentKeys.split(",");
    let updatedFormData = { ...formData }; // Cloning the existing formData
    let currentLevel: any = updatedFormData;

    // Looping through the keys and create nested objects
    keysArray.forEach((key: string, index: number) => {
      if (index === keysArray.length - 1) {
        // If it's the last key, assigning the value
        const data = [...currentLevel[key].data];
        data.splice(rowIndex, 1);
        currentLevel[key]["data"] = data;
      } else {
        // Otherwise, moving deeper into the object
        if (!currentLevel[key]) {
          currentLevel[key] = {}; // Creating only if it doesn't exist
        }
        currentLevel = currentLevel[key]; // Moving deeper into the object
      }
    });
    // Updating formData state with the updated object
    setFormData(updatedFormData);
  };

  // function for adding a new row in data field of a table
  // params: parentKeys : comma separated string of parentKeys till the table display,
  // headerCount: How many number of fields we need to add in data array.
  const addRowToTable = (parentKeys: string, headerCount: number) => {
    let keysArray = parentKeys.split(",");
    let updatedFormData = { ...formData }; // Cloning the existing formData
    let currentLevel: any = updatedFormData;

    // Looping through the keys and create nested objects
    keysArray.forEach((key: string, index: number) => {
      if (index === keysArray.length - 1) {
        // If it's the last key, adding a new row to the data object
        const data = [...currentLevel[key].data];
        // Create an array of empty strings based on the count
        if (headerCount > 0) {
          let emptyArray = Array(headerCount).fill("");

          // Push the array to data
          data.push(emptyArray);
        } else {
          data.push(["", ""]);
        }

        currentLevel[key]["data"] = data;
      } else {
        // Otherwise, moving deeper into the object
        if (!currentLevel[key]) {
          currentLevel[key] = {}; // Creating only if it doesn't exist
        }
        currentLevel = currentLevel[key]; // Moving deeper into the object
      }
    });
    // Updating formData state with the updated object
    setFormData(updatedFormData);
  };

  // function for updating state in nested array objects
  // params: parentKeys : comma separated string of parentKeys till the input field,
  // value: value of input,
  // index: index of array element that needs to be changed
  // changeKey: key name that needs to be changed
  const updateNestedStateOfArrayObject = (
    parentKeys: string,
    value: any,
    index: number,
    changeKey: string
  ) => {
    let keysArray = parentKeys.split(",");
    let updatedFormData = { ...formData }; // Cloning the existing formData
    let currentLevel: any = updatedFormData;

    // Looping through the keys and create nested objects
    keysArray.forEach((key: string, indexValue: number) => {
      if (indexValue === keysArray.length - 1) {
        // If it's the last key, assigning the value
        const data = [...currentLevel[key]];
        let obj = { ...data[index] };
        obj[changeKey] = value;

        data[index] = obj;
        let keyValue = key;
        currentLevel[keyValue] = data;
      } else {
        // Otherwise, moving deeper into the object
        if (!currentLevel[key]) {
          currentLevel[key] = {}; // Creating only if it doesn't exist
        }
        currentLevel = currentLevel[key]; // Moving deeper into the object
      }
    });

    // Updating formData state with the updated object
    setFormData(updatedFormData);
  };

  // function for displaying input field
  // params: parentKeys : comma separated string of parentKeys till the input field,
  // value: default value of input,
  // fieldKey: key name that needs to be as label for input field
  const renderInput = (parentKeys: string, value: any, fieldKey: string) => {
    // assigning input type based on value type
    const inputType =
      typeof value === "boolean"
        ? "checkbox"
        : typeof value === "number"
        ? "number"
        : "text";

    let keysArray = parentKeys.split(",");
    let isReadOnlyInput = false;
    let isImagesField = false;
    // Looping through the keys and check nested objects if there is any key named "images"
    // adding this loop for checking if images field exists, if exists we should disabled it in cam form
    keysArray.forEach((key: string, index: number) => {
      if (key === "images") {
        isImagesField = true;
      }
    });
    isReadOnlyInput =
      parentKeys?.includes("loan_details") ||
      parentKeys?.includes("borrower_details") ||
      isImagesField;
    // check if field key name has summary in it, if key has "summary" in it, we will render textarea else input field.
    return fieldKey?.includes("summary") ||
      fieldKey === "geolocation_report" ? (
      <textarea
        disabled={isReadOnlyInput}
        readOnly={isReadOnlyInput}
        rows={10}
        cols={80}
        value={value}
        onChange={(e) => updateNestedState(parentKeys, e.target.value)}
      ></textarea>
    ) : (
      <input
        disabled={isReadOnlyInput}
        readOnly={isReadOnlyInput}
        type={inputType}
        checked={
          inputType === "checkbox"
            ? value !== undefined && value !== null
              ? value
              : undefined
            : undefined
        }
        value={
          inputType !== "checkbox"
            ? value !== undefined && value !== null
              ? value
              : undefined
            : undefined
        }
        onChange={(e) =>
          updateNestedState(
            parentKeys,
            inputType === "checkbox" ? e.target.checked : e.target.value
          )
        }
      />
    );
  };
  // function for displaying table when type of object is table
  // params: parentKeys : comma separated string of parentKeys till the input field,
  // tableData: tableData is an object which has
  // main_header: name of table,
  // headers : header names that needs to be displayed(th),
  // data : field names that needs to be displayed(td),
  const renderTable = (parentKeys: string, tableData: any) => {
    const { main_header, headers, data } = tableData;

    const headerCount = headers?.length
      ? headers?.length
      : data?.length
      ? data[0]?.length
      : 0;

    return (
      <div>
        <div className="ac-table-main-header">
          <h3>{main_header}</h3>
          <IoMdAddCircle
            className="ac-add-icon-table"
            onClick={() => addRowToTable(parentKeys, headerCount)}
          />
        </div>

        <table>
          <thead>
            <tr>
              {headers.map((header: any, index: number) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((rowObj: any, rowIndex: number) => (
              <tr key={rowIndex}>
                {Object.values(rowObj).map((value: any, colIndex: number) => (
                  <td key={colIndex}>
                    <input
                      type={typeof value === "number" ? "number" : "text"}
                      value={
                        value !== undefined && value !== null
                          ? value
                          : undefined
                      }
                      onChange={(e) =>
                        updateDataInTable(
                          parentKeys,
                          [rowIndex, colIndex],
                          e.target.value
                        )
                      }
                    />
                  </td>
                ))}
                <td>
                  <IoMdRemoveCircle
                    className="ac-remove-icon-table"
                    onClick={(e) => removeRowFromTable(parentKeys, rowIndex)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Function for displaying sections
  const renderSection = (
    sectionKey: string,
    sectionData: any,
    parentKeys: string,
    level: number
  ) => {
    // checking if the type of object is "table"
    if (sectionData?.type !== undefined && sectionData?.type === "table") {
      return renderTable(parentKeys, sectionData);
    }
    if (sectionData) {
      return Object.keys(sectionData).map((key) => {
        const value = sectionData[key];
        // checking if the type of object and not array
        if (typeof value === "object" && !Array.isArray(value)) {
          if (value) {
            return (
              <div key={key} className="margin-section-data">
                {level === 1 ? (
                  <h3>{key.replace(/_/g, " ")}</h3>
                ) : (
                  <h4>{key.replace(/_/g, " ")}</h4>
                )}
                {renderSection(
                  sectionKey,
                  value,
                  parentKeys.concat("," + key),
                  2
                )}
              </div>
            );
          }
        }
        // checking if the type of object is an Array
        if (Array.isArray(value)) {
          return value ? (
            <div key={key} className="margin-section-data">
              {value ? <h3>{key.replace(/_/g, " ")}</h3> : null}
              {value?.map((each, index) => (
                <div key={index} className="margin-section-data-array">
                  <h4>{key.replace(/_/g, " ") + (index + 1)}</h4>
                  {Object.keys(each).map((eachKey) => (
                    <div key={eachKey} className="margin-array-key">
                      <label className="ac-input-label">
                        {eachKey.replace(/_/g, " ")}
                      </label>
                      <input
                        type={
                          typeof each[eachKey] === "boolean"
                            ? "checkbox"
                            : typeof each[eachKey] === "number"
                            ? "number"
                            : "text"
                        }
                        checked={
                          typeof each[eachKey] === "boolean"
                            ? each[eachKey]
                            : undefined
                        }
                        value={
                          typeof each[eachKey] !== "boolean"
                            ? each[eachKey]
                              ? each[eachKey]
                              : undefined
                            : undefined
                        }
                        onChange={(e) =>
                          updateNestedStateOfArrayObject(
                            parentKeys.concat("," + key),
                            typeof each[eachKey] === "boolean"
                              ? e.target.checked
                              : e.target.value,
                            index,
                            eachKey
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : null;
        }
        // rendering input if type is not object or array or table
        return (
          <div key={key} className="mainInput">
            <label className="ac-input-label">{key.replace(/_/g, " ")}</label>
            {renderInput(parentKeys.concat("," + key), value, key)}
          </div>
        );
      });
    }
  };

  // Function for handling saving cam
  const saveCam = async () => {
    try {
      // api call for submitting cam form
      setUpdateLoading(true);
      const apiObject = {
        application_id: application_id,
        data: formData,
      };
      const res = await saveCamData(apiObject);
      // response will have updated cam data
      if (res.data) {
        setUpdateLoading(false);
        let loanDetailedData = res?.data?.data?.application;
        localStorage.setItem("loanDetails", JSON.stringify(loanDetailedData));
        onSaveEdit(true, loanDetailedData);
        toast.success("Cam Saved Successfully");
      }
    } catch (e) {
      setUpdateLoading(false);
      toast.error("Error Updating Cam");
      console.log(JSON.parse(JSON.stringify(e)));
    }
  };

  // Function for handling publishing cam
  const publishCam = async () => {
    if (status !== "cam_review") {
      toast.error("You cannot Publish the CAM without saving");
    } else {
      try {
        // api call for submitting cam form
        setPublishLoading(true);
        const apiObject = {
          application_id: application_id,
        };
        const res = await publishCamData(apiObject);
        // response will have updated cam data
        if (res.data) {
          setPublishLoading(false);
          let loanDetailedData = res?.data?.data?.application;
          localStorage.setItem("loanDetails", JSON.stringify(loanDetailedData));
          onPublishEdit(true, loanDetailedData);
          toast.success("Cam Published Successfully");
        }
      } catch (e) {
        setPublishLoading(false);
        toast.error("Error Publishing Cam");
        console.log(JSON.parse(JSON.stringify(e)));
      }
    }
  };

  return (
    <form className="form-main-div">
      {Object.keys(formData).map((sectionKey, index) => (
        <Accordion
          className="margin-section-key"
          defaultExpanded={index === 0}
          key={sectionKey}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{ backgroundColor: "#f3ece3" }}
            className="mat-expansion-header-name"
          >
            {sectionKey.replace(/_/g, " ")}
          </AccordionSummary>
          <AccordionDetails>
            {renderSection(sectionKey, formData[sectionKey], sectionKey, 1)}
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="submit-button-div">
        <button
          type="button"
          disabled={updateLoading || publishLoading}
          onClick={saveCam}
          className="submitButton"
        >
          {updateLoading ? "Saving Please wait.." : "Save CAM"}
        </button>
        <button
          type="button"
          disabled={updateLoading || publishLoading}
          onClick={publishCam}
          className="submitButton"
        >
          {publishLoading ? "Publishing Please wait.." : "Publish CAM"}
        </button>
      </div>
    </form>
  );
};

export default GenerateCamForm;
