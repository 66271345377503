import { useEffect, useState } from "react";
import "./loanDetailedView.css";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  fetchFilesData,
  fetchImageData,
  getLoanCamDataApi,
} from "../../services/loanService";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BusinessPhotos from "./businessPhotos";
import React from "react";
import BusinessPd from "./businessPd";
import profileImage from "../../assets/logos/profile.png";
import GenerateCamForm from "./generateCamForm";
import CoBorrowerDetails from "./coBorrowerDetails";
import Tooltip from "@mui/material/Tooltip";

const sectionNames: any = [
  "Co-Borrower Details",
  "Business PD",
  "Business Photos",
  "Lifestyle Photos",
  "Collateral Photos",
  "Reference Details",
];

const LoanDetailedView = () => {
  const navigate = useNavigate();
  const [loanDetails, setLoanDetails] = useState<any>(
    JSON.parse(localStorage.getItem("loanDetails") || "")
  );
  const { selectedOrganisationId } = useSelector(
    (state: AppState) => state.loan
  );
  const { userDetails } = useSelector((state: AppState) => state.auth);
  const [orgIdData, setOrgIdData] = useState<any>(
    selectedOrganisationId ? selectedOrganisationId : userDetails?.orgId
  );
  const [borrowerDetails, setBorrowerDetails] = useState<any>({});
  const [initialDetails, setInitialDetails] = useState<any>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentParentIndex, setCurrentParentIndex] = useState<number>(0);
  const [borrowerImage, setBorrowerImage] = useState<string>();
  const [businessPhotos, setBusinessPhotos] = useState<any>([]);
  const [collateralPhotos, setCollateralPhotos] = useState<any>([]);
  const [familyPhotos, setFamilyPhotos] = useState<any>([]);
  const [businessPdData, setBusinessPdData] = useState<any>();
  const [camData, setCamData] = useState<any>();
  const [coBorrowersData, setCoBorrowersData] = useState<any>();
  const [loanStatus, setLoanStatus] = useState<any>(loanDetails?.status);
  const [coBorrowerCount, setCoBorrowerCount] = useState<number>(0);

  useEffect(() => {
    // Setting all the required data inorder to display in loan detailed view.
    if (loanDetails) {
      setLoanStatus(loanDetails?.status);

      let coBorrowerLength = coBorrowerCount;

      const coBorrowerDetailsIndex = returnIndexOfSection(
        "co_borrower_details"
      );
      if (coBorrowerDetailsIndex > -1) {
        const coBorrowerDetails =
          loanDetails.sections[coBorrowerDetailsIndex]?.data?.co_borrower
            ?.value;
        coBorrowerLength = coBorrowerDetails?.length;
        setCoBorrowerCount(coBorrowerDetails?.length);
        getCoBorrowerDetails(coBorrowerDetails);
      }

      getLoanData(loanDetails?._id);
      const businessPhotosIndex = returnIndexOfSection("business_photos");
      if (businessPhotosIndex > -1) {
        getPhotosData(
          loanDetails?.sections[businessPhotosIndex]?.data,
          "business_photos"
        );
      }

      const collateralPhotosIndex = returnIndexOfSection("collateral_photos");
      if (collateralPhotosIndex > -1) {
        getPhotosData(
          loanDetails?.sections[collateralPhotosIndex]?.data,
          "collateral_photos"
        );
      }

      const familyPhotosIndex = returnIndexOfSection("family_photos");
      if (familyPhotosIndex > -1) {
        getPhotosData(
          loanDetails?.sections[familyPhotosIndex]?.data,
          "family_photos"
        );
      }

      const businessPdIndex = returnIndexOfSection("business_pd");
      if (businessPdIndex > -1) {
        getBusinessPdData(loanDetails?.sections[businessPdIndex]?.data);
      }

      let profileDetails: any = {};
      const borrowerIndex = returnIndexOfSection("borrower_details");
      if (borrowerIndex > -1) {
        const borrower = loanDetails.sections[borrowerIndex];
        const fileId = borrower?.data?.borrower_photo?.value;
        if (fileId) {
          fetchBorrowerImage(orgIdData, fileId);
        }
        setInitialDetails({
          name: borrower?.data?.name?.value ? borrower?.data?.name?.value : "",
          email: borrower?.data?.email?.value
            ? borrower?.data?.email?.value
            : "",
        });
        profileDetails = {
          Mobile: borrower?.data?.mobile?.value,
          DOB: borrower?.data?.dob?.value ?? "-",
        };
      }

      const loanDataIndex = returnIndexOfSection("loan_details");
      if (loanDataIndex > -1) {
        const loanData = loanDetails.sections[loanDataIndex];
        profileDetails = {
          ...profileDetails,
          "Business Modal": loanData?.data?.business_model?.value ?? "-",
          "Business Industry": loanData?.data?.industry?.value ?? "-",
          "No Of Co-Borrowers": coBorrowerLength ? coBorrowerLength : 0,
          Quantam: loanData?.data?.quantum?.value
            ? loanData?.data?.quantum?.value
            : 0,
          Tenure: loanData?.data?.tenure?.value
            ? loanData?.data?.tenure?.value
            : 0,
        };
      }
      setBorrowerDetails(profileDetails);
    }
  }, [loanDetails]);

  // Common Function for getting section index
  const returnIndexOfSection = (key: string) => {
    const returnIndex = loanDetails?.sections?.findIndex(
      (each: any) => each.name === key
    );
    return returnIndex;
  };

  // api for getting detailed view data, currently not using as we are displaying everything from list view data itself.
  const getLoanData = async (applicationid: string) => {
    try {
      const res = await getLoanCamDataApi(applicationid);
      if (res?.data?.data?.application?.cams?.length) {
        setCamData(res?.data?.data?.application?.cams[0].output);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Common Function for fetching Photos data in order to display.
  const getPhotosData = async (data: any, type: string) => {
    try {
      let idsString = "";
      let businessData: any = Object.entries(data).map(([key, value]) => {
        if (data[key]?.type === "file" && data[key]?.value) {
          idsString =
            idsString === ""
              ? data[key]?.value
              : idsString + "," + data[key]?.value;
        }
        return {
          name: key.replace(/_/g, " "),
          id: data[key]?.value,
          image: null,
        };
      });
      if (idsString?.length) {
        const res = await fetchFilesData(orgIdData, idsString);
        const responseArray = res.data?.data?.applicationFiles;
        if (responseArray?.length) {
          businessData?.forEach((each: { id: any }, index: string | number) => {
            const fileIndex = responseArray?.findIndex(
              (eachFile: any) => eachFile._id === each.id
            );
            if (fileIndex > -1) {
              businessData[index].image =
                responseArray[fileIndex]?.presigned_url;
            }
          });
        }
        const finalData = businessData?.filter(
          (each: { image: any }) => each?.image
        );
        if (type === "business_photos") {
          setBusinessPhotos(finalData);
        } else if (type === "collateral_photos") {
          setCollateralPhotos(finalData);
        } else if (type === "family_photos") {
          setFamilyPhotos(finalData);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Function for getting Business Personal Data in order to display in Business PD tab.
  const getBusinessPdData = async (data: any) => {
    try {
      if (data.business_information.value) {
        const res = await fetchImageData(
          orgIdData,
          data.business_information.value
        );
        setBusinessPdData(res?.data?.data?.applicationFile?.presigned_url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Function for getting Borrow Image in order to display in profile.
  const fetchBorrowerImage = async (orgId: string, fileId: string) => {
    try {
      if (fileId) {
        const res = await fetchImageData(orgId, fileId);
        setBorrowerImage(res?.data?.data?.applicationFile?.presigned_url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Function for getting Co Borrow Details in order to display in Co Borrower Tab.
  const getCoBorrowerDetails = async (data: any) => {
    try {
      const coBorrowerArray = await Promise.all(
        data.map(async (eachBorrower: any) => {
          const response = await fetchImageData(
            orgIdData,
            eachBorrower?.value?.photo?.value
          );
          return {
            name: eachBorrower?.value?.name?.value ?? "-",
            image: response.data.data.applicationFile.presigned_url,
            dob: eachBorrower?.value?.dob?.value ?? "-",
            gender: eachBorrower?.value?.gender?.value ?? "-",
            relation: eachBorrower?.value?.relation?.value ?? "-",
          };
        })
      );
      setCoBorrowersData(coBorrowerArray);
    } catch (e) {
      console.log(e);
    }
  };

  const handleBack = () => {
    navigate("/loans");
  };

  const handleChange = (event: any, changedIndex: any) => {
    setCurrentIndex(changedIndex);
  };

  const handleParentChange = (event: any, changedIndex: any) => {
    setCurrentParentIndex(changedIndex);
  };

  const onCamSaveSuccess = (data: any, loanInfo: any) => {
    if (data) {
      setLoanStatus(loanInfo?.status);
      setCamData(loanInfo?.cams[0]?.output);
      onCamDataChange(loanInfo);
    }
  };

  const onPublishEditSuccess = (data: any, loanInfo: any) => {
    if (data) {
      setCurrentParentIndex(0);
      setLoanStatus("cam_available");
      onCamDataChange(loanInfo);
    }
  };

  const onCamDataChange = (loanInfo: any) => {
    let coBorrowerLength = coBorrowerCount;
    const loanDataIndex = loanInfo?.sections?.findIndex(
      (each: any) => each.name === "loan_details"
    );
    const coBorrowerDetailsIndex = loanInfo?.sections?.findIndex(
      (each: any) => each.name === "co_borrower_details"
    );
    if (coBorrowerDetailsIndex > -1) {
      const coBorrowerDetails =
        loanInfo.sections[coBorrowerDetailsIndex]?.data?.co_borrower?.value;
      coBorrowerLength = coBorrowerDetails?.length;
      setCoBorrowerCount(coBorrowerDetails?.length);
    }
    if (loanDataIndex > -1) {
      const sectionData = loanInfo.sections[loanDataIndex];
      const updatedData = {
        ...borrowerDetails,
        "Business Modal": sectionData?.data?.business_model?.value ?? "-",
        "Business Industry": sectionData?.data?.industry?.value ?? "-",
        "No Of Co-Borrowers": coBorrowerLength ? coBorrowerLength : 0,
        Quantam: sectionData?.data?.quantum?.value
          ? sectionData?.data?.quantum?.value
          : 0,
        Tenure: sectionData?.data?.tenure?.value
          ? sectionData?.data?.tenure?.value
          : 0,
      };
      setBorrowerDetails(updatedData);
    }
  };

  // Memoized functions for business photos, personal data and co-borrower data.
  const BusinessMemoized = React.memo(BusinessPhotos);
  const BusinessPdMemoized = React.memo(BusinessPd);
  const CoBorrowerMemoized = React.memo(CoBorrowerDetails);

  return (
    <div className="container">
      <div className="header">
        <IoArrowBack className="arrow-back-style" onClick={handleBack} />
        <div className="header-text">{loanDetails?._id}</div>
      </div>
      <div className="main-content">
        <div className="sidebar">
          <div className="side-bar-main-div">
            <div className="details-main-div">
              <div className="borrower-image-div">
                <img
                  src={borrowerImage ? borrowerImage : profileImage}
                  alt="borrower_image"
                  className="image-style"
                />
              </div>
              <Tooltip title={initialDetails?.name}>
                <div className="font-size-main">{initialDetails?.name}</div>
              </Tooltip>
              <Tooltip title={initialDetails?.email}>
                <div className="font-size-main">{initialDetails?.email}</div>
              </Tooltip>
            </div>
            <div className="sidebar-info">
              <div className="details-text">Details</div>
              {Object.entries(borrowerDetails).map(([key, value]) => (
                <div className="item" key={key}>
                  <span className="key font-size-left">{key}</span>
                  <span className="value font-size-left">
                    {borrowerDetails[key]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="parent-header">
            <Tabs value={currentParentIndex} onChange={handleParentChange}>
              <Tab value={0} label=" Borrower Details" />
              {(loanStatus === "verification_pending" ||
                loanStatus === "cam_review") &&
                userDetails?.isAdmin && <Tab value={1} label="Edit CAM" />}
            </Tabs>
          </div>
          {currentParentIndex === 0 && (
            <div className="details">
              <Tabs
                value={currentIndex}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value={0} label="Co-Borrower Details" />
                <Tab value={1} label="Business PD" />
                <Tab value={2} label="Business Photos" />
                <Tab value={3} label="Lifestyle Photos" />
                <Tab value={4} label="Collateral Photos" />
                <Tab value={5} label="Reference Details" />
              </Tabs>
            </div>
          )}
          <div
            className={
              currentParentIndex === 0 ? "main-area" : "main-area-edit-cam"
            }
          >
            {currentParentIndex === 0 ? (
              currentIndex === 5 ? (
                <div className="ac-no-data-style">No Reference Details</div>
              ) : currentIndex === 2 ||
                currentIndex === 3 ||
                currentIndex === 4 ? (
                <BusinessMemoized
                  data={
                    currentIndex === 2
                      ? businessPhotos
                      : currentIndex === 3
                      ? familyPhotos
                      : collateralPhotos
                  }
                />
              ) : currentIndex === 1 ? (
                <BusinessPdMemoized audioFile={businessPdData} />
              ) : currentIndex === 0 ? (
                <CoBorrowerMemoized data={coBorrowersData} />
              ) : null
            ) : (
              <div className="edit-cam-main-div">
                {camData && loanDetails?._id ? (
                  <GenerateCamForm
                    data={camData}
                    application_id={loanDetails?._id}
                    onPublishEdit={onPublishEditSuccess}
                    status={loanStatus}
                    onSaveEdit={onCamSaveSuccess}
                  />
                ) : (
                  <div className="ac-no-data-style">No Cam Data To Edit</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDetailedView;
