import { SET_SELECTED_ORGID,SET_SELECTED_FILTER } from "../actions/types";
import { LoanActionTypes } from "../models/actionTypes/loanActionType";

  
  const initialState = {
    selectedOrganisationId: "",
    selectedFilter: "open",
  };
  
  export default function (state = initialState, action: LoanActionTypes): any {
    switch (action.type) {
      case SET_SELECTED_ORGID:
        return {
          ...state,
          selectedOrganisationId: action.payload,
        };
        case SET_SELECTED_FILTER:
          return {
            ...state,
            selectedFilter: action.payload,
          };
      default:
        return state;
    }
  }