import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_PATH } from "../config/config";

export const logInApi = (credentials: {
  channel: string | undefined;
  address: string;
  country_code: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(API_PATH().apis.signIn, credentials);
};

export const verifyOtp = (credentials: {
  channel: string | undefined;
  address: string;
  country_code: string;
  otp: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(API_PATH().apis.verifyOtp, credentials);
};

export const getBranchesList = (orgId: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      org_id: orgId,
    },
  };
  return axios.get(API_PATH().apis.getBranches, configurations);
};
export const getProductsList = (orgId: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      org_id: orgId,
    },
  };
  return axios.get(API_PATH().apis.getProducts, configurations);
};

export const getRolesList = () => {
  return axios.get(API_PATH().apis.getRoles);
};

export const getOrganisations = () => {
  return axios.get(API_PATH().apis.getOrganisations);
}