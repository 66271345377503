import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./loansList.css";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AppState, store } from "../../redux/store";
import { getLoanList } from "../../services/loanService";
import {
  setSelectedFilter,
  setSelectedOrganisationId,
} from "../../redux/actions/loanActions";

interface loanObject {
  id: string;
  branchName: string;
  productName: string;
  borrowerName: string;
  status: string;
}

const LoansList = () => {
  const { branches, userDetails, products, organisations } = useSelector(
    (state: AppState) => state.auth
  );
  const { selectedOrganisationId, selectedFilter } = useSelector(
    (state: AppState) => state.loan
  );
  const [loansList, setLoansList] = useState<loanObject[]>([]);
  const [totalLoansList, setTotalLoansList] = useState([]);
  const navigate = useNavigate();
  const [selectedStatusFilter, setSelectedStatusFilter] =
    useState(selectedFilter);
  const [selectedOrgId, setSelectedOrgId] = useState(
    selectedOrganisationId ? selectedOrganisationId : userDetails?.orgId
  );
  const [loanData, setLoanData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedOrgId(selectedOrganisationId);
  }, [selectedOrganisationId]);

  useEffect(() => {
    setSelectedStatusFilter(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    getLoans(selectedOrgId);
  }, []);

  const getLoans = async (orgId: any) => {
    try {
      setLoading(true);
      const res = await getLoanList(orgId);
      const branchesValue = localStorage.getItem("branches");
      let branchesInfo = JSON.parse(branchesValue || "");

      const productsValue = localStorage.getItem("products");
      let productsInfo = JSON.parse(productsValue || "");
      const productsData = products?.length ? products : productsInfo;
      const branchesData = branches?.length ? branches : branchesInfo;
      const loanDataInfo = res.data?.data?.applications?.map(
        (eachLoan: any) => {
          const borrower = eachLoan.sections.find(
            (eachSection: any) => eachSection.name === "borrower_details"
          ).data?.name?.value;

          const productName = productsData?.find(
            (eachProduct: any) => eachProduct._id === eachLoan.product_id
          )?.name;
          const branchName = branchesData?.find(
            (eachBranch: any) => eachBranch._id === eachLoan.branch_id
          )?.name;
          return {
            id: eachLoan._id,
            borrowerName: borrower,
            branchName: branchName ? branchName : "Unknown Branch",
            status: eachLoan.status,
            productName: productName ? productName : "Unknown Product",
          };
        }
      );
      setLoading(false);
      setLoansList(
        loanDataInfo?.filter((each: any) =>
          selectedStatusFilter === "open"
            ? each.status.toLowerCase() === "created"
            : each.status.toLowerCase() !== "created"
        )
      );
      setTotalLoansList(res?.data?.data?.applications);
      setLoanData(loanDataInfo);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onFilterChange = (event: any) => {
    const value = event.target.value;
    let loansArray = [...loanData];
    if (value === "open") {
      loansArray = loansArray.filter(
        (each: any) => each.status.toLowerCase() === "created"
      );
    } else {
      loansArray = loansArray.filter(
        (each: any) => each.status.toLowerCase() !== "created"
      );
    }
    setSelectedFilter(value);
    store.dispatch(setSelectedFilter(value));
    setLoansList(loansArray);
  };

  const onOrgChange = (event: any) => {
    const value = event.target.value;
    setSelectedOrgId(value);
    store.dispatch(setSelectedOrganisationId(value));
    getLoans(value);
  };
  const viewLoan = (loan: any) => {
    const loanDetails = totalLoansList.find(
      (eachLoan: any) => eachLoan._id === loan.id
    );
    if (loanDetails) {
      localStorage.setItem("loanDetails", JSON.stringify(loanDetails));
      navigate("/loan-detailed-view");
    }
  };

  return (
    <div className="loan-list-main">
      {organisations?.length && userDetails?.isAdmin ? (
        <div className="loan-header-select">
          <label className="organisation-select-text">
            Select Organisation :{" "}
          </label>
          <select
            value={selectedOrgId}
            onChange={onOrgChange}
            className="organisation-select"
          >
            {organisations?.map((eachOrg: any) => {
              return (
                <option key={eachOrg?.name} value={eachOrg?._id}>
                  {eachOrg?.name}
                </option>
              );
            })}
          </select>
        </div>
      ) : null}
      <div className="loan-header">
        <div className="loan-header-text">Loan Applications</div>
        {loanData?.length ? (
          <div className="ac-select-style">
            <select
              value={selectedStatusFilter}
              onChange={onFilterChange}
              className="filter-select"
            >
              <option value="open">Open</option>
              <option value="closed">Closed</option>
            </select>
          </div>
        ) : null}
      </div>

      {!loading ? (
        loansList?.length ? (
          <div id="loans-table" className="tableMainDiv">
            <table>
              <thead>
                <tr>
                  <th>Borrower Name</th>
                  <th>Product Name</th>
                  <th>Branch Name</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {loansList.map((eachLoan: any) => {
                  return (
                    <tr key={eachLoan.id}>
                      <td className="text-transform-upper">
                        {eachLoan.borrowerName}
                      </td>
                      <td>{eachLoan.productName}</td>
                      <td>{eachLoan.branchName}</td>
                      <td className="ac-status">{eachLoan.status}</td>
                      <td>
                        <FaEye
                          className="ac-cursor-pointer"
                          onClick={() => viewLoan(eachLoan)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="ac-no-data-loans-list">
            No {loanData?.length ? selectedStatusFilter : ""} Loans to display
          </div>
        )
      ) : (
        <div className="ac-no-data-loans-list">Loading...</div>
      )}
    </div>
  );
};

export default LoansList;
