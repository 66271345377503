import {
  IS_ERROR,
  IS_ERROR_MSG,
  SET_THEME,
  START_LOADING,
  STOP_LOADING,
  SET_ENVIRONMENT,
  SET_BASE_URL,
} from "../actions/types";
import { AppLevelStateActionTypes } from "../models/actionTypes/appLevelStateActionType";
import { AppLevelState } from "../models/appLevelStateActionType";

export enum BackendType {
  Development = "development",
  Staging = "staging",
  Production = "production",
  Local = "local",
}

const API_ENDPOINT: any = {
  local: "http://localhost:8000/api/v1",
  development: "https://col-api-qa.ablecredit.com/api/v1",
  staging: "https://col-api-stg1.ablecredit.com/api/v1",
  production: "https://api.ablecredit.com/api/v1",
};

const BASE_URL: any = {
  development: "",
  staging: "",
  production: "",
  local: "",
};

const initialState: AppLevelState = {
  loading: false,
  isError: false,
  isErrorMsg: undefined,
  isDark: false,
  api_url: API_ENDPOINT.production,
  baseUrl: BASE_URL.development,
};

export default function (
  state = initialState,
  action: AppLevelStateActionTypes
): AppLevelState {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        isDark: action.payload,
      };
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case IS_ERROR_MSG:
      return {
        ...state,
        isErrorMsg: action.payload,
      };
    case SET_ENVIRONMENT:
      return {
        ...state,
        api_url: API_ENDPOINT[action.payload],
      };
    case SET_BASE_URL:
      return {
        ...state,
        baseUrl: BASE_URL[action.payload],
      };
    default:
      return state;
  }
}
