import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_TOKEN,
  SET_USER_BRANCHES,
  SET_USER_ORGANISATIONS,
  SET_USER_PRODUCTS,
  SET_USER_ROLES,
} from "../actions/types";
import { AuthActionTypes } from "../models/actionTypes/authActionType";

const initialState = {
  userDetails: undefined,
  token: "",
  branches: [],
  roles: {},
  products: [],
  organisations: [],
};

export default function (state = initialState, action: AuthActionTypes): any {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {};
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_USER_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
      };
    case SET_USER_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case SET_USER_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}
