import axios, { AxiosRequestConfig } from "axios";
import { API_PATH } from "../config/config";

export const getLoanList = (orgId: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      org_id: orgId,
    },
  };
  return axios.get(API_PATH().apis.getLoans, configurations);
};

export const getLoanDataApi = (applicationid: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      id: applicationid,
      edit: 1,
    },
  };
  return axios.get(API_PATH().apis.getLoanData, configurations);
};

export const getLoanCamDataApi = (applicationid: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      id: applicationid,
      edit: 1,
    },
  };
  return axios.get(API_PATH().apis.getLoanCamData, configurations);
};

export const fetchImageData = (orgId: string, fileId: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      id: fileId,
      orgId: orgId,
    },
  };
  return axios.get(API_PATH().apis.getImageUrl, configurations);
};

export const saveCamData = (data: any) => {
  return axios.put(API_PATH().apis.saveCam, data);
};

export const publishCamData = (data: any) => {
  return axios.put(API_PATH().apis.publishCam, data);
};

export const fetchFilesData = (orgId: string, fileId: string) => {
  const configurations: AxiosRequestConfig = {
    params: {
      id: fileId,
      orgId: orgId,
    },
  };
  return axios.get(API_PATH().apis.getMultipleFiles, configurations);
};
