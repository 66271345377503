import { AppActions } from "../models/actionTypes/appActionType";
import {
  SET_SELECTED_FILTER,
  SET_SELECTED_ORGID,
} from "./types";




export const setSelectedOrganisationId = (orgs: any): AppActions => ({
  type: SET_SELECTED_ORGID,
  payload: orgs,
});

export const setSelectedFilter = (filter: any): AppActions => ({
  type: SET_SELECTED_FILTER,
  payload: filter,
});
