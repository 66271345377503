import logo from "../../assets/logos/logo.png";
import "../auth/leftView.css";

const LeftView = () => {
  return (
    <div className="left-view-main-div">
      {/* <img src={lg} alt="banner_image" className="bannerImage" /> */}
      <div className="leftContentContainer">
        <img src={logo} alt="logo" className="logoStyle" />
        {/* <div className="logoText">
            AbleCredit
            </div> */}
        <div className="mainText">
          AI that writes Credit Reports on its own !!
        </div>
        <div className="childText">
          AbleCredit is your friendly, supportive credit assistant. It generates
          Credit Appraisal Memos based on your policies, without any human
          intervention.‍
        </div>
      </div>
    </div>
  );
};

export default LeftView;
