import { AppActions } from "../models/actionTypes/appActionType";
import { Dispatch } from "redux";
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_TOKEN,
  SET_USER_BRANCHES,
  SET_USER_PRODUCTS,
  SET_USER_ROLES,
  SET_USER_ORGANISATIONS,
} from "./types";

import { errorLogging, startLoading, stopLoading } from "./appStateAction";
import { UserDetails } from "../models/authDetails";
import { store } from "../store";
import {
  getBranchesList,
  getOrganisations,
  getProductsList,
  getRolesList,
  verifyOtp,
} from "../../services/authService";
import interceptor from "../../interceptor";
import { toast } from "react-toastify";

export const setUserData = (details: any): AppActions => ({
  type: LOGIN_SUCCESS,
  payload: details,
});
export const logOut = (): AppActions => ({
  type: LOGOUT_SUCCESS,
});
export const setToken = (token: string | undefined): AppActions => ({
  type: SET_TOKEN,
  payload: token,
});

export const setBranches = (branches: any): AppActions => ({
  type: SET_USER_BRANCHES,
  payload: branches,
});

export const setProducts = (products: any): AppActions => ({
  type: SET_USER_PRODUCTS,
  payload: products,
});

export const setUserRoles = (userRoles: any): AppActions => ({
  type: SET_USER_ROLES,
  payload: userRoles,
});

export const setUserOrganisations = (orgs: any): AppActions => ({
  type: SET_USER_ORGANISATIONS,
  payload: orgs,
});

export const logInUser =
  (
    loginCredentials: {
      channel: string | undefined;
      address: string;
      country_code: string;
      otp: string;
    },
    navigate: any
  ) =>
  async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(startLoading());
      let paramsData = {
        channel: loginCredentials.channel,
        address: loginCredentials.address,
        country_code: loginCredentials.country_code,
        otp: loginCredentials.otp,
      };
      let res = await verifyOtp(paramsData);
      if (res?.data?.data?.user) {
        const userDetails = res.data.data?.user;
        const userObj: UserDetails = {
          token: userDetails?.token,
          orgId: userDetails?.org_id,
          name: userDetails?.name,
          userId: userDetails?.id,
          isAdmin: userDetails?.admin,
        };
        dispatch(setUserData(userObj));
        dispatch(setToken(userDetails?.token));
        localStorage.setItem("ac_user", JSON.stringify(userDetails));
        localStorage.setItem("token", userDetails?.token);
        localStorage.setItem("orgId", userDetails?.org_id);

        interceptor.interceptor();
        toast.success("Logged In Successfully");
        // Fetching roles data, branches, and products and organisations list before navigating to loanslist
        if (userDetails?.org_id) {
          await fetchInitialData(userDetails?.org_id); // This will trigger branches and products APIs
          navigate("/organisations");
        }
      } else {
        toast.error(JSON.parse(JSON.stringify(res?.data?.msg)));
      }
    } catch (e: any) {
      toast.error(JSON.parse(JSON.stringify(e?.response?.data?.msg)));
      store.dispatch(errorLogging(false));
    } finally {
      dispatch(stopLoading());
    }
  };

const fetchInitialData = async (orgId: string) => {
  try {
    await Promise.all([
      fetchBranches(orgId),
      fetchProducts(orgId),
      fetchRoles(),
      fetchOrganisations(),
    ]);
  } catch (e) {
    console.log("Error fetching initial data: ", e);
  }
};

const fetchBranches = async (org: string) => {
  try {
    const res = await getBranchesList(org);
    localStorage.setItem("branches", JSON.stringify(res?.data?.data?.branches));
    store.dispatch(setBranches(res?.data?.data?.branches));
  } catch (e) {
    console.log(e);
  }
};

const fetchProducts = async (org: string) => {
  try {
    const res = await getProductsList(org);
    localStorage.setItem("products", JSON.stringify(res?.data?.data?.products));
    store.dispatch(setProducts(res?.data?.data?.products));
  } catch (e) {
    console.log(e);
  }
};

const fetchRoles = async () => {
  try {
    const res = await getRolesList();
    localStorage.setItem("roles", JSON.stringify(res?.data?.data?.roles));
    const OrganisationRole = {
      organisation: res?.data?.data?.organisation,
      roles: res?.data?.data?.roles,
    };
    store.dispatch(setUserRoles(OrganisationRole));
  } catch (e) {
    console.log(e);
  }
};

const fetchOrganisations = async () => {
  try {
    const res = await getOrganisations();
    localStorage.setItem(
      "organisations",
      JSON.stringify(res?.data?.data?.organisations)
    );
    store.dispatch(setUserOrganisations(res?.data?.data?.organisations));
  } catch (e) {
    console.log(e);
  }
};
