import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUser, FaListAlt, FaUsers, FaCodeBranch } from "react-icons/fa";
import { CgOrganisation } from "react-icons/cg";
import { AiOutlineProduct } from "react-icons/ai";
import { ImSection } from "react-icons/im";
import "../menusidebar/menuSideBar.css";
import { AppState, store } from "../../redux/store";
import { logOut, setToken } from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import {
  TbArrowBadgeRightFilled,
  TbArrowBadgeLeftFilled,
} from "react-icons/tb";

import {
  setSelectedFilter,
  setSelectedOrganisationId,
} from "../../redux/actions/loanActions";
import { useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";

const MenuSideBar = () => {
  const { userDetails } = useSelector((state: AppState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);

  const isActive = (path: string) => location.pathname === path;

  const logOutUser = () => {
    localStorage.removeItem("ac_user");
    localStorage.removeItem("auth_details");
    localStorage.removeItem("ac_org_role");
    localStorage.removeItem("token");
    localStorage.clear();
    store.dispatch(setToken(undefined));
    store.dispatch(setSelectedOrganisationId(undefined));
    store.dispatch(setSelectedFilter("open"));
    store.dispatch(logOut());
    navigate("/login");
    toast.success("Logged Out Successfully");
  };

  const onCloseMenu = (state: boolean) => {
    setIsMenuOpened(state);
  };

  return (
    <div className={!isMenuOpened ? "menuSideBar-Closed" : "menuSideBar"}>
      <div className={!isMenuOpened ? "mainDivMenu-Closed" : "mainDivMenu"}>
        <div className="menuItem">
          <Link
            to="/organisations"
            className={
              isMenuOpened
                ? isActive("/organisations")
                  ? "active open"
                  : "open"
                : isActive("/organisations")
                ? "active closed"
                : "closed"
            }
          >
            <CgOrganisation
              className={isMenuOpened ? "menuIcon" : "menuIcon-closed"}
            />
            {isMenuOpened && <span className="menuText">Organisations</span>}
          </Link>
        </div>
        {userDetails?.isAdmin ? (
          <div className="menuItem">
            <Link
              to="/loans"
              className={
                isMenuOpened
                  ? isActive("/loans") || location.pathname?.includes("loan")
                    ? "active open"
                    : "open"
                  : isActive("/loans") || location.pathname?.includes("loan")
                  ? "active closed"
                  : "closed"
              }
            >
              <FaListAlt
                className={isMenuOpened ? "menuIcon" : "menuIcon-closed"}
              />
              {isMenuOpened && (
                <span className="menuText">Loan Applications</span>
              )}
            </Link>
          </div>
        ) : null}
        <div className="menuItem">
          <Link
            to="/products"
            className={
              isMenuOpened
                ? isActive("/products")
                  ? "active open"
                  : "open"
                : isActive("/products")
                ? "active closed"
                : "closed"
            }
          >
            <AiOutlineProduct
              className={isMenuOpened ? "menuIcon" : "menuIcon-closed"}
            />
            {isMenuOpened && <span className="menuText">Products</span>}
          </Link>
        </div>
        <div className="menuItem">
          <Link
            to="/users"
            className={
              isMenuOpened
                ? isActive("/users")
                  ? "active open"
                  : "open"
                : isActive("/users")
                ? "active closed"
                : "closed"
            }
          >
            <FaUsers
              className={isMenuOpened ? "menuIcon" : "menuIcon-closed"}
            />
            {isMenuOpened && <span className="menuText">Users</span>}
          </Link>
        </div>
        <div className="menuItem">
          <Link
            to="/profile"
            className={
              isMenuOpened
                ? isActive("/profile")
                  ? "active open"
                  : "open"
                : isActive("/profile")
                ? "active closed"
                : "closed"
            }
          >
            <FaUser className={isMenuOpened ? "menuIcon" : "menuIcon-closed"} />
            {isMenuOpened && <span className="menuText">Profile</span>}
          </Link>
        </div>
        <div className="menuItem">
          <Link
            to="/branches"
            className={
              isMenuOpened
                ? isActive("/branches")
                  ? "active open"
                  : "open"
                : isActive("/branches")
                ? "active closed"
                : "closed"
            }
          >
            <FaCodeBranch
              className={isMenuOpened ? "menuIcon" : "menuIcon-closed"}
            />
            {isMenuOpened && <span className="menuText">Branches</span>}
          </Link>
        </div>
        <div className="menuItem">
          <Link
            to="/sections"
            className={
              isMenuOpened
                ? isActive("/sections")
                  ? "active open"
                  : "open"
                : isActive("/sections")
                ? "active closed"
                : "closed"
            }
          >
            <ImSection
              className={isMenuOpened ? "menuIcon" : "menuIcon-closed"}
            />
            {isMenuOpened && <span className="menuText">Sections</span>}
          </Link>
        </div>
        <div
          className={isMenuOpened ? "logoutDiv-Open" : "logoutDiv-Close"}
          onClick={() => logOutUser()}
        >
          <MdLogout className="logoutIcon" />
          {isMenuOpened && <div className="ac-log-out">Logout</div>}
        </div>
      </div>
      {isMenuOpened && (
        <TbArrowBadgeLeftFilled
          className="menu-toggle-icon-close"
          onClick={() => onCloseMenu(false)}
        />
      )}
      {!isMenuOpened && (
        <TbArrowBadgeRightFilled
          className="menu-toggle-icon-open"
          onClick={() => onCloseMenu(true)}
        />
      )}
    </div>
  );
};

export default MenuSideBar;
