import { AppActions } from "../models/actionTypes/appActionType";
import { BackendType } from "../reducers/appStateReducer";
import {
  IS_ERROR,
  IS_ERROR_MSG,
  SET_THEME,
  START_LOADING,
  STOP_LOADING,
  SET_ENVIRONMENT,
  SET_BASE_URL,
} from "./types";

export const setTheme = (isDark: boolean): AppActions => ({
  type: SET_THEME,
  payload: isDark,
});
export const startLoading = (): AppActions => ({
  type: START_LOADING,
});

export const stopLoading = (): AppActions => ({
  type: STOP_LOADING,
});
export const errorLogging = (value: boolean): AppActions => ({
  type: IS_ERROR,
  payload: value,
});
export const errorLoggingMsg = (value: any): AppActions => ({
  type: IS_ERROR_MSG,
  payload: value,
});
export const setEnvironment = (type: BackendType): AppActions => ({
  type: SET_ENVIRONMENT,
  payload: type,
});
export const setBaseUrl = (url: string): AppActions => ({
  type: SET_BASE_URL,
  payload: url,
});
