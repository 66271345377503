import { useEffect, useState } from "react";
import LeftView from "./leftView";
import { useNavigate } from "react-router-dom";
import "../auth/verifyOtp.css";
import { logInUser } from "../../redux/actions/authActions";
import { AppState, store } from "../../redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const VerifyOtp = () => {
  const token = useSelector((state: AppState) => state.auth.token);
  const [otp, setOtp] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (event: any) => {
    setOtp(event.target.value);
  };

  useEffect(() => {
    if (token) {
      navigate("/organisations");
    }
  }, [token]);

  const handleVerify = async (event: any) => {
    event.preventDefault();
    try {
      if (!otp) {
        toast.error("OTP is invalid");
        setError("OTP is invalid");
        setShowAlert(true);
      }
      const authDetails = JSON.parse(
        JSON.stringify(localStorage.getItem("auth_details"))
      );
      if (!authDetails) {
        navigate("/login");
      }
      const auth = JSON.parse(authDetails);
      store.dispatch(
        logInUser(
          {
            channel: auth.channel,
            address: auth.address,
            country_code: auth.country_code,
            otp,
          },
          navigate
        )
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="mainContainer">
      <div className="leftContainer">
        <LeftView />
      </div>
      <div className="rightContainer">
        <div className="rightHeader">
          <div className="loginText">Verify Otp</div>
        </div>
        <form onSubmit={handleVerify} className="formClass">
          <div className="labelTextEmail">Enter OTP</div>

          <input
            type="text"
            name="email"
            onChange={handleChange}
            className="inputField"
          />
          <button className="sendOtpButton" type="submit">
            Verify Otp
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
