import { useEffect, useRef } from "react";
import "./businessPd.css";

const BusinessPd = ({ audioFile }: { audioFile: string }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = audioFile;
      audioRef.current.load(); // Loading the the new source
      audioRef.current.currentTime = 0.1;
      audioRef.current.preload = "metadata";
      audioRef.current.preservesPitch = true;
      audioRef.current.addEventListener("ended", () => {
        if (audioRef.current) {
          audioRef.current.currentTime = 0.1;
          audioRef.current.preload = "metadata";
          audioRef.current.preservesPitch = true;
        }
      });
    }
  }, [audioFile]);

  return (
    <div className="ac-business-pd-main">
      {audioFile ? (
        <audio
          ref={audioRef}
          controls
          className="ac-audio-style"
          controlsList="nodownload"
        >
          <source src={audioFile} type="audio/aac" />
          Your browser does not support the audio tag.
        </audio>
      ) : (
        <div className="ac-no-data">No Business Personal Data to Display</div>
      )}
    </div>
  );
};

export default BusinessPd;
