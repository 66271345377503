import React, { useState } from "react";
import "./businessPhotos.css";

const BusinessPhotos = ({ data }: any) => {
  const [photosData, setPhotosData] = useState<any>(data);
  return (
    <div
      className={
        photosData?.length
          ? "business-photos-main"
          : "business-photos-main-no-data"
      }
    >
      {photosData?.length ? (
        photosData?.map((each: any) => {
          return (
            <div key={each.image} className="photo-main-div">
              <div className="ac-business-text">{each.name}</div>
              <img
                src={each.image}
                alt="business photo"
                className="business-image"
              />
            </div>
          );
        })
      ) : (
        <div className="ac-no-data">No Photos to Display</div>
      )}
    </div>
  );
};

export default BusinessPhotos;
