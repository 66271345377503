import { AppState, store } from "../redux/store";

const API_URL = () => (store.getState() as AppState).appStateReducer.api_url;
export const API_PATH = () => ({
  apis: {
    signIn: API_URL() + "/auth/login",
    verifyOtp: API_URL() + "/auth/verify",
    getBranches: API_URL() + "/dashboard/branches/list",
    getProducts: API_URL() + "/dashboard/products/list",
    getRoles: API_URL() + "/dashboard/home",
    getOrganisations: API_URL() + "/dashboard/organisations/list",
    getLoans: API_URL() + "/app/applications/listApplicationsByOrg",
    getLoanData: API_URL() + "/app/applications/view",
    getLoanCamData: API_URL() + "/app/applications/get-cam",
    getImageUrl: API_URL() + "/app/application-files/view",
    getMultipleFiles: API_URL() + "/app/application-files/getFiles",
    saveCam: API_URL() + "/app/applications/update-cam",
    publishCam: API_URL() + "/app/applications/publish-cam",
  },
});
